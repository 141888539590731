<template>
  <el-row>
    <el-col :span="24">
      <el-card shadow="never" style="height: 500px;overflow-y: auto;" class="uploadali-el-card-style">
        <div class="uploadImage" v-for="(item) in srcList" :key="item.url">
          <el-image
              style="width: 100px; height: 100px"
              :src="item.url"
              :preview-src-list="listString(srcList)">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import { getTmsUploadImage } from '@/api/basic/uploadAli'

export default {
  name: 'd2-container-tms-hd',
  data () {
    return {
      loading: false,
      activeName: 'picture',
      srcList: [],
      fileSrcList: [],
      videoSrcList: []
    }
  },
  props: {
  },
  watch: {
  },
  methods: {
    listString (imageList) {
      var imageArray = []
      imageList.forEach(item => {
        imageArray.push(item.url)
      })
      return imageArray
    },
    // 关闭时清空srclist
    clearSrcList () {
      this.srcList = []
    },
    // 获取上传返回的数据
    getOssReturnData (docNo) {
      this.loading = true
      getTmsUploadImage(Object.assign({
        objectNo: docNo
      })).then(item => {
        console.log('获取返回值', item.data)
        this.loading = false
        if (item.code === 0) {
          item.data.forEach(urls => {
            let option = {}
            option.id = urls.id
            option.url = urls.url
            option.fileName = urls.fileName
            option.itemType = urls.itemType
            option.fileType = urls.fileType
            option.objectNo = urls.objectNo
            this.srcList.push(option)
          })
        }
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.uploadali-el-card-style {
    .el-card__header {
        padding: 14px 20px !important;
        border-bottom: 0 !important;
    }
}
.uploadImage {
    position: relative;
    display: inline-block;
    margin-left: 17px;
    .optionMark {
        display: none;
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50px;
        top: 50px;
        background:linear-gradient(to top left,rgba(0,0,0,.5) 40%,transparent 40%);

        .el-upload-list__item-actions {
            position: absolute;
            font-size: 15px;
            right: 5px;
            top: 26px;
            width: 100%;
            height: 100%;
            text-align: right;
            color: #fff;
            transition: opacity .3s;
        }
    }
}
.uploadImage:hover {
    .optionMark {
        display: block;
        z-index: 999;
        .el-upload-list__item-preview {
            cursor: pointer;
        }
    }
}
/*文件下载删除 鼠标移入样式改变*/
.file_download:hover{
    cursor: pointer;
    .file_download_delete{display: block};
}
/*文件下载删除按钮*/
.file_download_delete{
  display: none;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-tms-hd/index.vue"
}
</vue-filename-injector>

<template>
  <div class="ag_grid_table_style" style="width: 100%;height: 100%;">
    <ag-grid-vue
        v-if="isShowTable"
        v-loading="loading"
        element-loading-spinner="td_loading_icon"
        :element-loading-text="loading_text"
        ref="ag_grid_vue"
        class="table ag-theme-balham"
        :style="table_style"
        :rowSelection="rowSelection"
        :allowContextMenuWithControlKey="true"
        :suppressContextMenu="true"
        :animateRows="true"
        :rowData="rowData"
        :localeText="localeText"
        @grid-ready="onGridReady"
        :getRowStyle="getRowStyle"
        :modules="modules"
        :rowMultiSelectWithClick="rowMultiSelectWithClick"
        :suppressCellSelection="suppressCellSelection"
        :overlayNoRowsTemplate="overlayNoRowsTemplate"
        :defaultColDef="defaultColDef"
        :pinnedBottomRowData="pinnedBottomRowData"
        :pinnedTopRowData="pinnedTopRowData"
        :suppressRowClickSelection="suppressRowClickSelection"
        :rowHeight="rowHeight"
        @cellClicked="cellClicked"
        @cellDoubleClicked="cellDoubleClicked"
        @cellFocused="cellFocused"
        @cellMouseOver="cellMouseOver"
        @cellMouseOut="cellMouseOut"
        @cellMouseDown="cellMouseDown"
        @rowClicked="rowClicked"
        @rowDoubleClicked="rowDoubleClicked"
        @rowSelected="rowSelected">
      <!-- 打勾选择 -->
      <ag-grid-column v-if="isSelection" headerName="" field="checkRow"
                      :width="checkWidth" :checkboxSelection="true" :headerCheckboxSelection="true" :filter="false" :sortable="false" pinned="left">
      </ag-grid-column>
      <!-- 序号 -->
      <ag-grid-column v-if="isSelection" headerName="序号" field="total"
                      :width="width" :minWidth="minWidth" maxWidth="300" :filter="false" :sortable="false" pinned="left" :valueGetter="function (params) { return params.node.rowPinned ? params.data.total : indexMark ? parseInt((parseInt(page_count) - 1) * parseInt(page_size)) + parseInt(params.node.rowIndex) + 1 : parseInt(params.node.rowIndex) + 1 }">
      </ag-grid-column>
      <!--列信息 -->
      <template v-for="(item, index) in mtableColumns">
        <ag-grid-column :editable="item.tableType === 'openCompute'" :key="index"
                        :headerName="item.title" :field="item.prop" :hide="hideColumn" :pinned="item.tableFixed"
                        :minWidth="item.minwidth" :width="item.minwidth" :sortable="item.tableSort === '0'" :filter="filterType(item.tableType, item.tableFilter)" :filterParams="filterParams(item.tableType)"
                        cellRendererFramework="cellRenderer" :cellRendererParams="setSlost(item)">
        </ag-grid-column>
      </template>
    </ag-grid-vue>
    <vue-context-menu :contextMenuData="contextMenuData" @copeAction="copeAction" @hideRow="hideRow" @hideCheckRow="hideCheckRow" @hideUnCheckRow="hideUnCheckRow" @checkOld="checkOld" style="padding: 5px;"></vue-context-menu>
  </div>
</template>

<script>
// ag-grid引用 cellRendererFramework="cellRenderer" :cellRendererParams="item"
// :cellRenderer="(params) => cellRenderer(params, item)"
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import dayjs from 'dayjs'
// 引入ag-grid-vue
import { AgGridVue } from 'ag-grid-vue'
import { getDecodeStore, setEncodeStore } from '@/tdcommon/store'
import { validatenull } from '@/tdcommon/validate'
import { getDictUrl, getTableUser } from '@api/sys.ui'
import { getdict } from '@api/admin/dict'
// 自定义组件
import cellRenderer from '@/components/d2-container-data/cellRenderer'
import { deepClone, floatSub, moneyToNumFiled } from '@/tdcommon/beeutil'

export default {
  name: 'd2-container-data',
  // 注册draggable组件
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    cellRenderer
  },
  props: {
    ModularName: {
      type: String,
      default: () => ''
    },
    checkWidth: {
      type: Number,
      default: () => 50
    },
    width: {
      type: Number,
      default: () => 50
    },
    minWidth: {
      type: Number,
      default: () => 50
    },
    page_count: {
      type: Number,
      default: () => 0
    },
    page_size: {
      type: Number,
      default: () => 0
    },
    page_total: {
      type: Number,
      default: () => 0
    },
    page_amount: {
      type: Object,
      default: () => {}
    },
    // 合计是否显示
    isSum: {
      type: Boolean,
      default: () => true
    },
    // 合计显示位置
    sumSite: {
      type: String,
      default: () => 'bottom'
    },
    // 启动分页累加序号
    indexMark: {
      type: Boolean,
      default: () => false
    },
    // 表格显示数据
    Data: {
      type: Array,
      default: () => []
    },
    // 启动加载动画
    loading: {
      type: Boolean,
      default: () => false
    },
    // 是否分页
    isPage: {
      type: Boolean,
      default: () => false
    },
    // 打勾选择
    isSelection: {
      type: Boolean,
      default: () => false
    },
    // 序号显示
    isIndex: {
      type: Boolean,
      default: () => false
    },
    // 加载文字信息
    loading_text: {
      type: String,
      default: () => '正在加载数据，请稍等.....'
    },
    // No data信息
    overlayNoRowsTemplate: {
      type: String,
      default: () => '<div class="no_data"><div><i class="no_data_title"></i></div><div><span class="no_data_text">太难了，看来我满足不了你的需求</span></div></div>'
    },
    // 表格高度
    table_style: {
      type: String,
      default: () => 'width: 100%;height: 100%;'
    },
    // 选择列模型 ‘single’单选 ‘multiple’多选
    rowSelection: {
      type: String,
      default: () => 'multiple'
    },
    // 单击就选中，如果为false需要按ctrl再点击才能选中多行
    rowMultiSelectWithClick: {
      type: Boolean,
      default: () => false
    },
    // 如果为true，则单元格将不可选。 这意味着单击它们时单元格不会获得键盘焦点.
    suppressCellSelection: {
      type: Boolean,
      default: () => false
    },
    // 如果为true，则单击行时将不会发生行选择。仅在需要复选框选择时使用。默认：false
    suppressRowClickSelection: {
      type: Boolean,
      default: () => true
    },
    // 隐藏列组
    hideColumn: {
      type: Boolean,
      default: () => false
    },
    rowHeight: {
      type: Number,
      default: () => 28
    }
  },
  data () {
    return {
      gridApi: null,
      columnApi: null,
      paramsApi: null,
      isShowTable: false,
      mtableColumns: [],
      rowData: this.Data,
      defaultColDef: {
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        floatingFilter: true
      },
      // 设置固定在底部的行
      pinnedBottomRowData: [],
      // 设置置顶行
      pinnedTopRowData: [],
      contextMenuData: {
        menuName: 'right-mark',
        axis: { x: null, y: null },
        menulists: [{ fnHandler: 'copeAction', icoName: 'icon-el-mark el-icon-copy-document', btnName: '复制' },
          { fnHandler: 'hideRow', icoName: 'icon-el-mark el-icon-remove-outline', btnName: '隐藏当前记录' },
          { fnHandler: 'hideCheckRow', icoName: 'icon-el-mark el-icon-finished', btnName: '隐藏已勾选的记录' },
          { fnHandler: 'hideUnCheckRow', icoName: 'icon-el-mark el-icon-document-checked', btnName: '隐藏未勾选的记录' },
          { fnHandler: 'checkOld', icoName: 'icon-el-mark el-icon-refresh', btnName: '还原隐藏的记录' }],
        obj: {}
      },
      // 汉化
      localeText: {
        // Set Filter
        selectAll: '全选',
        selectAllSearchResults: '全选查询结果',
        searchOoo: '搜索中...',
        blanks: '空格',
        noMatches: '不相同',

        // Number Filter & Text Filter
        filterOoo: '筛选中...',
        equals: '相同',
        notEqual: '不相同',
        empty: '空数据',

        // Number Filter
        lessThan: '小于',
        greaterThan: '大于',
        lessThanOrEqual: '小于等于',
        greaterThanOrEqual: '大于等于',
        inRange: '范围',
        inRangeStart: '开始范围',
        inRangeEnd: '结束范围',

        // Text Filter
        contains: '包含',
        notContains: '不包含',
        startsWith: '头部包含',
        endsWith: '尾部包含',

        // Date Filter
        dateFormatOoo: 'yyyy-mm-dd',

        // Filter Conditions
        andCondition: '并',
        orCondition: '或',

        // Filter Buttons
        applyFilter: '应用',
        resetFilter: '重置',
        clearFilter: '清空筛选',
        cancelFilter: '关闭筛选',

        // Filter Titles
        textFilter: '文本筛选',
        numberFilter: '数字筛选',
        dateFilter: '日期筛选',
        setFilter: '设置筛选'
      },
      isShowFilterCol: false,
      modules: []
    }
  },
  beforeMount () {
    this.getTableUI()
  },
  watch: {
    Data: function (newVal) {
      this.rowData = newVal
      if (this.sumSite === 'top') {
        if (this.pinnedTopRowData.length === 2) {
          this.pinnedTopRowData.splice(1, 1)
          this.gridApi.setPinnedTopRowData(this.pinnedTopRowData)
        }
      } if (this.sumSite === 'bottom') {
        if (this.pinnedBottomRowData.length === 2) {
          this.pinnedBottomRowData.splice(0, 1)
          this.gridApi.setPinnedBottomRowData(this.pinnedBottomRowData) // 在底部显示合计行
        }
      }
    },
    page_amount: function (newVal) {
      if (this.isSum && this.page_count === 1) {
        // 设置固定在底部的行
        this.pinnedBottomRowData = []
        // 设置置顶行
        this.pinnedTopRowData = []

        // 单页的平均业务毛利率、平均实际毛利率
        // let profit = Number(newVal.profit)
        // let realProfit = Number(newVal.realProfit)
        // let totalAmountFee = Number(newVal.totalAmountFee)
        // newVal.profitRate = ((profit / totalAmountFee) * 100).toFixed(2)
        // newVal.realProfitRate = ((realProfit / totalAmountFee) * 100).toFixed(2)

        var sumData = newVal
        sumData.checkRow = '合计'
        if (this.sumSite === 'top') {
          this.pinnedTopRowData.push(sumData)
        } if (this.sumSite === 'bottom') {
          this.pinnedBottomRowData.push(sumData)
        }
      }
    }
  },
  methods: {
    // 取得查询条件界面参数
    getTableUI () {
      this.isShowTable = false
      let tableHear = getDecodeStore({ name: 'tb' + this.ModularName })
      if (validatenull(tableHear)) {
        getTableUser(this.ModularName).then(response => {
          this.mtableColumns = response.data.tableColumns.filter(item => item.isShowTable === '0')
          this.settingDataUrl(0)
        })
      } else {
        this.mtableColumns = tableHear
        this.isShowTable = true
      }
    },
    /**
     * 实例化组件时通过for进行对DataUrl远程请求数据处理方法及处理是否可以显示
     */
    settingDataUrl (index) {
      if (index === this.mtableColumns.length - 1) {
        setEncodeStore('tb' + this.ModularName, this.mtableColumns)
        this.isShowTable = true
        return
      }
      if (!validatenull(this.mtableColumns[index]) && !validatenull(this.mtableColumns[index].prop)) {
        if (!validatenull(this.mtableColumns[index].editOptions)) {
          this.mtableColumns[index].editOptions = this.setTag(JSON.parse(this.mtableColumns[index].editOptions))
          this.settingDataUrl(index + 1)
        } else if (!validatenull(this.mtableColumns[index].dictUrl)) {
          getDictUrl(this.mtableColumns[index].dictUrl).then((response) => {
            this.mtableColumns[index].editOptions = this.setTag(response.data)
            this.settingDataUrl(index + 1)
          })
        } else if (!validatenull(this.mtableColumns[index].dictType)) {
          getdict(this.mtableColumns[index].dictType).then(response => {
            this.mtableColumns[index].editOptions = this.setTag(response.data)
            this.settingDataUrl(index + 1)
          })
        } else {
          this.settingDataUrl(index + 1)
        }
      } else {
        this.settingDataUrl(index + 1)
      }
    },
    // 表格创建完成后执行事件
    onGridReady (params) {
      // 获取gridApi
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.paramsApi = params
      this.gridApi.setFloatingFiltersHeight(0)
      // 调整表格列宽大小自适应
      // this.gridApi.sizeColumnsToFit()
      this.$emit('customColumnHide', params.columnApi)
    },
    // 通过打勾点击事件 隐藏表格列组
    setColumnHide (columns, boolen) {
      // console.log('boolen', boolen)
      this.columnApi.setColumnsVisible(columns, boolen)
    },
    staticsCount (newRowData, subRowData) {
      var checkAmount = deepClone(this.page_amount)
      if (this.sumSite === 'top') {
        if (this.pinnedTopRowData.length === 2) {
          checkAmount = deepClone(this.pinnedTopRowData[1])
        }
      } if (this.sumSite === 'bottom') {
        if (this.pinnedBottomRowData.length === 2) {
          checkAmount = deepClone(this.pinnedBottomRowData[0])
        }
      }
      if (!validatenull(newRowData)) {
        for (let i = 0, len = newRowData.length; i < len; i++) {
          for (let key in this.page_amount) {
            if (!validatenull(key)) {
              if (i === 0) {
                checkAmount[key] = 0
              }
              checkAmount[key] += newRowData[i][key]
              if (i === len - 1) {
                checkAmount[key] = Math.floor(checkAmount[key] * 100) / 100
              }
            }
          }
        }
      }
      if (!validatenull(subRowData)) {
        for (let i = 0, len = subRowData.length; i < len; i++) {
          for (let key in this.page_amount) {
            if (!validatenull(key)) {
              if (i === 0) {
                checkAmount[key] = moneyToNumFiled(checkAmount[key])
              }
              checkAmount[key] = floatSub(checkAmount[key], subRowData[i][key])
              if (i === len - 1) {
                checkAmount[key] = Math.floor(checkAmount[key] * 100) / 100
              }
            }
          }
        }
      }

      var sumData = checkAmount
      sumData.checkRow = '合计'
      sumData.total = !validatenull(newRowData) ? newRowData.length : moneyToNumFiled(checkAmount.total) - subRowData.length
      if (this.sumSite === 'top') {
        if (this.pinnedTopRowData.length === 2) {
          this.pinnedTopRowData[1] = sumData
          this.gridApi.setPinnedTopRowData(this.pinnedTopRowData)
        } else {
          this.pinnedTopRowData.push(sumData)
        }
      } if (this.sumSite === 'bottom') {
        if (this.pinnedBottomRowData.length === 2) {
          this.pinnedBottomRowData[0] = sumData
          this.gridApi.setPinnedBottomRowData(this.pinnedBottomRowData) // 在底部显示合计行
        } else {
          this.pinnedBottomRowData.splice(0, 0, sumData)
        }
      }
    },
    // 空值统一检查方法
    valIsnull (val) {
      return !validatenull(val)
    },
    // tag处理
    setTag (options) {
      options.forEach(items => {
        items.type = validatenull(items.type) ? '' : items.type
        items.size = validatenull(items.size) ? 'mini' : items.size
        items.effect = validatenull(items.effect) ? 'light' : items.effect
        items.color = validatenull(items.color) ? '' : items.color
        items.style = validatenull(items.color) ? '' : items.color
      })
      return options
    },
    // 日期格式处理
    formDate (formType, Dates) {
      return dayjs(Dates).format(formType)
    },
    getOptions (options, val) {
      let option = {}
      options.forEach(item => {
        if (item.value === val) {
          option = item
        }
      })
      return option
    },
    // slot处理
    setSlost (val) {
      if (!validatenull(val.dictType)) {
        getdict(val.dictType).then(response => {
          val.editOptions = JSON.stringify(response.data)
        })
      }
      if (!validatenull(val.dictUrl)) {
        getDictUrl(val.dictUrl).then(response => {
          val.editOptions = JSON.stringify(response.data)
        })
      }
      if (val.tableIsSlot === '0') {
        val.slots = this.$slots
        val.scopedSlots = this.$scopedSlots
      }
      if (val.tableType === 'openDetails') {
        val.openDetails = this.openDetails
      }
      val.showMenu = this.showMenu
      return val
    },
    // a标签点击
    openDetails (row) {
      this.$emit('openDetails', row)
    },
    // 单元格被点击时
    cellClicked (params) {
      this.$emit('cellClicked', params)
    },
    // 单位格被双击时
    cellDoubleClicked (params) {
      this.$emit('cellDoubleClicked', params)
    },
    // 单元格获得焦点
    cellFocused (params) {
      this.$emit('cellFocused', params)
    },
    // 鼠标进入单元格
    cellMouseOver (params) {
      this.$emit(' ', params)
    },
    // 鼠标离开单元格/鼠标离开单元格/鼠标按下
    cellMouseOut (params) {
      this.$emit('cellMouseOut', params)
    },
    // 鼠标按下
    cellMouseDown (params) {
      this.$emit('cellMouseDown', params)
    },
    // 行被点击。
    rowClicked (params) {
      this.$emit('rowClicked', params)
    },
    // 行被双击。
    rowDoubleClicked (params) {
      this.$emit('rowDoubleClicked', params)
    },
    // 取得选中列表
    getSelectedRows () {
      return this.gridApi.getSelectedRows()
    },
    // 行被选择。
    rowSelected (params) {
      this.$emit('rowSelected', params)
    },
    // 设置固定行样式
    getRowStyle (params) {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', 'color': 'red' }
      } else if (params.data.docDate === '小计') {
        return { 'font-weight': 'bold', 'background-color': '#e8eaec' }
      }
    },
    // 上下文菜单功能
    showMenu (event, data) {
      event.preventDefault()
      var x = event.clientX
      var y = event.clientY
      this.contextMenuData.obj = data
      this.contextMenuData.axis = { x, y }
    },
    // 复制
    copeAction () {
      this.$copyText(this.contextMenuData.obj.value).then((res) => {
        this.$message({
          showClose: true,
          message: '内容已复制到剪切板！',
          type: 'success'
        })
      }).catch(() => {
        this.$message.error('抱歉，复制失败！')
      })
    },
    // 全部勾选
    allCheck () {
      this.gridApi.selectAll()
    },
    // 取消勾选
    unCheck () {
      this.gridApi.deselectAll()
    },
    // 隐藏当前记录
    hideRow () {
      this.gridApi.updateRowData({ remove: [this.contextMenuData.obj.data] })
      this.staticsCount(null, [this.contextMenuData.obj.data])
    },
    // 隐藏已勾选的记录
    hideCheckRow () {
      let selRows = this.gridApi.getSelectedRows()
      if (selRows.length <= 0) {
        this.$message.error('抱歉，请选择要进行隐藏的数据！')
      } else {
        this.gridApi.updateRowData({ remove: selRows })
        this.staticsCount(null, selRows)
      }
    },
    // 隐藏未勾选的记录
    hideUnCheckRow () {
      let selRows = this.gridApi.getSelectedRows()
      if (selRows.length <= 0) {
        this.$message.error('抱歉，请选择要进行保留的数据！')
      } else {
        this.gridApi.setRowData(selRows)
        this.staticsCount(selRows, null)
      }
    },
    // 还原隐藏信息
    checkOld () {
      this.gridApi.setRowData(this.rowData)
      if (this.sumSite === 'top') {
        if (this.pinnedTopRowData.length === 2) {
          this.pinnedTopRowData.splice(1, 1)
          this.gridApi.setPinnedTopRowData(this.pinnedTopRowData)
        }
      } if (this.sumSite === 'bottom') {
        if (this.pinnedBottomRowData.length === 2) {
          this.pinnedBottomRowData.splice(0, 1)
          this.gridApi.setPinnedBottomRowData(this.pinnedBottomRowData) // 在底部显示合计行
        }
      }
    },
    // 检索类型处理
    filterType (tableType, isOpen) {
      if (isOpen === '1') {
        return false
      }
      var type = ''
      switch (tableType) {
        case 'date':
        case 'datetime':
          type = 'agDateColumnFilter'
          break
        case 'num':
          type = 'agNumberColumnFilter'
          break
        default:
          type = 'agTextColumnFilter'
          break
      }
      return type
    },
    // 显示列筛选器
    isShowColSearch () {
      this.isShowFilterCol = !this.isShowFilterCol
      this.clearSearch()
      if (this.isShowFilterCol) {
        this.gridApi.setFloatingFiltersHeight(32)
      } else {
        this.gridApi.setFloatingFiltersHeight(0)
      }
    },
    // 清空过滤
    clearSearch () {
      this.gridApi.setFilterModel(null)
    },
    // 日期处理
    filterParams (tableType, isOpen) {
      if (isOpen === '1') {
        return {}
      }
      if (tableType === 'date' || tableType === 'datetime') {
        return {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = dayjs(cellValue).format('YYYY/MM/DD HH:mm:ss')
            if (dateAsString == null) return -1
            var cellDate = new Date(dateAsString)
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1
            }
          },
          browserDatePicker: true,
          buttons: ['reset'],
          minValidYear: 2019
        }
      }
      return {
        // 自定义过滤器选项
        filterOptions: [
          // 包含
          'contains',
          // 不包含
          'notContains',
          // 相同
          'equals',
          // 不相同
          'notEqual',
          // 头部包含
          'startsWith',
          // 尾部包含
          'endsWith',
          // 空值
          {
            displayKey: '空',
            displayName: '空',
            test: (filterValue, cellValue) => validatenull(cellValue),
            hideFilterInput: true
          },
          // 非空值
          {
            displayKey: '非空',
            displayName: '非空',
            test: (filterValue, cellValue) => !validatenull(cellValue),
            hideFilterInput: true
          }
        ],
        buttons: ['reset']
      }
    }
  }
}
</script>

<style lang="scss">
.ag_grid_table_style {
  .ag-theme-balham .ag-ltr .ag-cell {
    border-right-color: var(--ag-border-color, #bdc3c7);
  }
  .td_loading_icon{
    display: inline-block;
    width: 100px;
    height: 100px;
    background-image: url("./loading.gif");
    background-position: center center;
    background-size: 300px 300px;
  }
  .no_data {
    position: absolute;
  }
  .no_data_title {
    display: inline-block;
    width: 150px;
    height: 150px;
    background-image: url("./empty.png");
    background-position: center center;
    background-size: 150px 150px;
  }
  .no_data_text {
    font-size: 16px;
    color: #909399;
  }
  .vue-contextmenuName-right-mark {
    .context-menu-list {
      color: #606266;
      font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .context-menu-list:hover {
      color: #FFFFFF;
      background-color: rgb(121 187 255);
    }
  }
  .icon-el-mark {
    font-size: 16px;
    margin-left: 5px;
  }
  .ag-theme-balham .ag-header {
    //background: #F0F3FA;
    background: #EDEFF2;
  }
  .ag-theme-balham .ag-cell {
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #343434;
  }
  .ag-header-cell-text {
    //font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    //font-weight: 500;
    color: #343434;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-data/index.vue"
}
</vue-filename-injector>

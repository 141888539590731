import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/cus/tmsyd/page',
    method: 'post',
    data: query
  })
}
/**
 * 打印
 */
export function printYD (obj) {
  return request({
    url: '/cus/tmsyd/prt',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 编辑详情 通过id获取数据
 */
export function getDetails (id) {
  return request({
    url: '/cus/tmsyd/ordersearch/' + id,
    method: 'get'
  })
}
/**
 * 定位 通过车牌号获取返回数据
 */
export function getGpsPosition (obj) {
  return request({
    url: '/cus/gps/pos',
    method: 'get',
    params: { carNo: obj }
  })
}

import request from '@/plugin/axios'
/**
 * 上传阿里 获取签名
 */
export function uploadAli () {
  return request({
    url: '/sup/hdupload/sign',
    method: 'get'
  })
}
/**
 * 上传阿里 客户资料保存到后台
 */
export function addUploadAli (obj) {
  return request({
    url: '/sup/hdupload/hd',
    method: 'post',
    data: obj
  })
}
/**
 * 删除上传阿里的后台数据
 */
export function delOssData (obj) {
  return request({
    url: '/sup/hdupload/delete',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 通过itemType区分 获取返回数据接口
 */
export function getOssObjectList (obj) {
  return request({
    url: '/sup/hdupload/list',
    method: 'get',
    params: obj
  })
}
/**
 * 获取TMS系统OSS图片
 */
export function getTmsUploadImage (obj) {
  return request({
    url: '/sup/hdupload/getTmsUploadImage',
    method: 'post',
    params: obj
  })
}

import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/cus/tmsdd/page',
    method: 'post',
    data: query
  })
}

/**
 * 新增保存
 */
export function savaDD (obj) {
  return request({
    url: '/cus/tmsdd/savedd',
    method: 'post',
    data: obj
  })
}
/**
 * 修改保存
 */
export function updateDD (obj) {
  return request({
    url: '/cus/tmsdd/updatedd',
    method: 'post',
    data: obj
  })
}
/**
 * 编辑详情 通过id获取数据
 */
export function getData (id) {
  return request({
    url: '/cus/tmsdd/details/' + id,
    method: 'get'
  })
}

/**
 * 订单 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/cus/tmsdd/delids',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量审核
 */
export function examineObjs (ids) {
  return request({
    url: '/tms/tmsdd/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量反审核
 */
export function unexamineObjs (ids) {
  return request({
    url: '/tms/tmsdd/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量审单
 */
export function auditObjs (ids) {
  return request({
    url: '/tms/tmsdd/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量反审单
 */
export function unauditObjs (ids) {
  return request({
    url: '/tms/tmsdd/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 订单导入
 */
export function excelImport (file, id) {
  return request({
    url: '/cus/tmsdd/import',
    method: 'post',
    params: { file: file, id: id }
  })
}
/**
 * 导入 返回结果
 */
export function returnImport () {
  return request({
    url: '/cus/tmsdd/import/progress',
    method: 'get'
  })
}
/**
 * 打印
 */
export function printDD (obj) {
  return request({
    url: '/cus/tmsdd/print',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 编辑详情 通过id获取数据
 */
export function getDetails (id) {
  return request({
    url: '/cus/tmsdd/detailsById/' + id,
    method: 'get'
  })
}

<template>
  <div class="amap-page-container">
    <div :style="{width:'100%',height:'430px'}">
      <el-amap vid="amapDemo" class="amap-demo" :center="center" :zoom="zoom" :amap-manager="amapManager" :events="events">
        <div v-for="(item, index) in markers" :key="index">
          <el-amap-marker vid="component-marker" :position="item.position" :events="item.events" :label="{ content: item.content, offset: item.offset }"></el-amap-marker>
        </div>
        <el-amap-info-window v-if="window" :position="window.position" :visible="visible" :content="window.content" :offset="window.offset"></el-amap-info-window>
      </el-amap>
    </div>
  </div>
</template>
<script>

import { getGpsPosition } from '@/api/customer/tmsyd'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
import { validatenull } from '@/tdcommon/validate'
let amapManager = new AMapManager() // 新建生成地图画布
export default {
  name: 'd2-container-yd-location',
  data () {
    let self = this
    return {
      lng: 0,
      lat: 0,
      zoom: 9,
      center: [121.59996, 31.197646],
      amapManager,
      windows: [],
      window: '',
      visible: false,
      marker: {},
      markers: [],
      events: {
        click (e) {
          const { lng, lat } = e.lnglat
          self.lng = lng
          self.lat = lat
        },
        init (o) {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initPage() // 初始化数据self指向this
            // self.changeMap() // 绑定鼠标缩放事件
            // self.setLine() // 画一条北京到莫斯科的路线
          })
        }
      }
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
    // this.getInit()
    // this.point()
  },
  methods: {
    initPage () {},
    getCarPositionMsg (carno) {
      if (validatenull(carno)) {
        this.center = [121.59996, 31.197646]
        this.markers = []
        this.window = ''
        return ''
      } else {
        getGpsPosition(carno).then(response => { // 豫FB5520
          if (response.code === 0) {
            // 点
            let markers = []
            // 信息窗体
            let windows = []
            let _this = this
            console.log('res.data', response.data)
            console.log('JSON.parse(response.data)', JSON.parse(response.data))
            let resObj = JSON.parse(response.data)
            this.center = [(Number(resObj.lon) / 600000).toFixed(6), (Number(resObj.lat) / 600000).toFixed(6)]
            // 点
            markers.push({
              position: [(Number(resObj.lon) / 600000).toFixed(6), (Number(resObj.lat) / 600000).toFixed(6)],
              events: {
                click () {
                  _this.windows.forEach(window => {
                    _this.visible = false // 关闭窗体
                  })

                  _this.window = _this.windows[0]
                  _this.$nextTick(() => {
                    _this.visible = true // 点击点坐标，出现信息窗体
                  })
                }
              },
              lon: (Number(resObj.lon) / 600000).toFixed(6),
              lat: (Number(resObj.lat) / 600000).toFixed(6),
              content: '【' + carno + '】 ' + resObj.spd + 'km/h',
              offset: [25, 5]
            })
            // 信息窗体
            windows.push({
              position: [(Number(resObj.lon) / 600000).toFixed(6), (Number(resObj.lat) / 600000).toFixed(6)],
              content: '地址：' + resObj.adr,
              offset: [0, -30]
            })
            this.markers = markers
            this.windows = windows
          } else {
            this.center = [121.59996, 31.197646]
            this.markers = []
            this.window = ''
            this.$alert('未找到此车牌号的定位信息！', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
              }
            })
            // this.$message.error('未找到此车牌号的定位信息！')
          }
        })
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
  .amap-demo {
    height: 300px;
  }
  .toolbar {
    margin-top: 10px;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-yd-location/index.vue"
}
</vue-filename-injector>

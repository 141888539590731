
















































export default function (Component) {
  Component.options.__source = "src/components/d2-container-msg-list/index.vue"
}

import request from '@/plugin/axios'

// export function fetchList (query) {
//   return request({
//     url: '/admin/user/page',
//     method: 'get',
//     params: query
//   })
// }
/*
* 动态后台排序接口 *
*/
export function fetchList (query) {
  return request({
    url: '/admin/user/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/admin/user',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/admin/user/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/admin/user/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/admin/user',
    method: 'put',
    data: obj
  })
}
/*
*用户名 判断重复 *
*/
export function getDetails (obj) {
  return request({
    url: '/admin/user/details/' + obj,
    method: 'get'
  })
}

export function getBranchUser (obj) {
  return request({
    url: '/admin/user/branchlist/' + obj,
    method: 'get'
  })
}

export function dingUserSync () {
  return request({
    url: '/admin/user/userSyc',
    method: 'get'
  })
}
export function alluser () {
  return request({
    url: '/admin/user/alluser',
    method: 'post'
  })
}
// 发送短信
export function sendSMS (mobile) {
  return request({
    url: '/admin/user/sendMobileCode/' + mobile,
    method: 'get'
  })
}
// 修改密码
export function resetPassword (obj) {
  return request({
    url: '/admin/user/resetPassWord',
    method: 'put',
    data: obj
  })
}
// 通过ID取得用户权限信息
export function getUserMeusList (obj) {
  return request({
    url: '/admin/user/userAllPermission',
    method: 'post',
    data: obj
  })
}

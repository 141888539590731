import request from '@/plugin/axios'

/**
 * 通过输入客户名称远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function cusName (custname) {
  return request({
    url: '/basic/customer/getcust',
    method: 'get',
    params: { custname: custname }
  })
}
/**
 * 通过输入客户名称远程搜索客户信息（停用的也能搜索）
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function cusAllName (custname) {
  return request({
    url: '/basic/customer/getcustall',
    method: 'get',
    params: { custname: custname }
  })
}
/**
 * 通过输入客户名称远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getProjectName (custname) {
  return request({
    url: '/basic/customer/getproject',
    method: 'get',
    params: { custname: custname }
  })
}
/**
 * 项目名称options返回所有
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getCusAll (custname) {
  return request({
    url: '/basic/customer/getcustallS',
    method: 'get',
    params: { custname: custname }
  })
}
